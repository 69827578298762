import * as React from "react";
import styled from "@emotion/styled";
import { Link, useStaticQuery } from "gatsby";
import { Context } from "../components/GlobalContext";

const SiteContainer = styled.div`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

const LeftHalf = styled.div`
  flex-grow: 0.3;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 1rem;
  }
  &::-webkit-scrollbar-track {
    border-left: 1px solid black;
  }
  &::-webkit-scrollbar-thumb {
    background-color: black;
  }
  @media screen and (max-width: 1400px) {
    flex-grow: 1;
  }
  @media screen and (max-width: 980px) {
    border-bottom: 1px solid black;
  }
`;

const RightHalf = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  border-left: 1px solid black;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 980px) {
    border-left: 0px;
  }
`;

const HomeButtonContainer = styled.div`
  height: 3.3rem;
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid black;
  padding: 1rem 1.333rem;
`;

const HomeText = styled.p`
  margin-top: 26vh;
  margin-left: 1.333rem;
  padding-right: 1rem;
  max-width: 30rem;
  @media screen and (max-width: 980px) {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
`;

const MobileNum = styled.span`
  color: var(--blue);
  display: none;
  margin-right: 0.4rem;
  margin-bottom: 0.1rem;
  @media screen and (max-width: 980px) {
    display: inline-block;
  }
`;

const NumberContainer = styled.div`
  width: 100%;
  height: clamp(10rem, 30vh, 25rem);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid black;
  font-size: clamp(10rem, 20vh, 25rem);
  padding-left: 5rem;
  padding-bottom: 1rem;
  color: var(--blue);
  user-select: none;
  @media screen and (max-width: 1260px) {
    padding-left: 2rem;
  }
  @media screen and (max-width: 980px) {
    display: none;
  }
`;

const WorkshopsList = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 4.5rem;
  padding-left: 6rem;
  padding-right: 1rem;
  padding-bottom: 20%;
  &::-webkit-scrollbar {
    width: 1rem;
  }
  &::-webkit-scrollbar-track {
    border-left: 1px solid black;
  }
  &::-webkit-scrollbar-thumb {
    background-color: black;
  }
  @media screen and (max-width: 1260px) {
    padding-left: 3rem;
  }
  @media screen and (max-width: 980px) {
    padding-top: 1rem;
    padding-left: 1.333rem;
    padding-right: 2rem;
    padding-bottom: 20%;
  }
`;

const WorkshopLink = styled(Link)`
  display: block;
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  &:hover {
    opacity: 1 !important;
    :after {
      content: "";
      display: block;
      position: absolute;
      left: -6rem;
      width: 0;
      height: 0;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 30px solid var(--blue);
      @media screen and (max-width: 980px) {
        display: none;
      }
    }
  }
`;

// markup
const NotFoundPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark {
          nodes {
            frontmatter {
              slug
              course_leader
              title
              subtitle
            }
          }
        }
      }
    `
  );
  return (
    <SiteContainer>
      <LeftHalf>
        <HomeButtonContainer>
          <Link to='/'>{data.site.siteMetadata.title}</Link>
        </HomeButtonContainer>
        <HomeText>
          The Research 1 Methods workshop is a taught programme introducing
          first year master students to research in architecture. It aims at
          developing methodological and conceptual capabilities that are
          applicable to the disciplines of architecture. The subject of the
          different workshops will reflect the symbiosis between design practice
          and history and theory and allows for scholarships to be placed within
          a deeper understanding of architecture as a whole. The workshops will
          be developed through a series of weekly meetings and intensive working
          sessions. Research is here understood as a range of techniques
          employed to generate knowledge about design that can be mobilized in
          design. It is conducted through a set of tools and apparatuses for
          inquiry from diagramming and mapping to model making and from archival
          research to oral history. Each Research 1 Methods workshop will be
          assessed by the submission of a portfolio of text and images that
          includes both group and individual work.
        </HomeText>
      </LeftHalf>
      <RightHalf>
        <Context.Consumer>
          {(context) => (
            <>
              <NumberContainer>{context.data.workshopNum}</NumberContainer>
              <WorkshopsList>
                {data.allMarkdownRemark.nodes.map((workshop, index) => {
                  return (
                    <WorkshopLink
                      to={workshop.frontmatter.slug}
                      onMouseEnter={() => {
                        context.updateWorkshopNum(index + 1);
                      }}
                      onMouseLeave={() => {
                        context.updateWorkshopNum("RM");
                      }}
                    >
                      <MobileNum>RM{index + 1}</MobileNum>
                      {workshop.frontmatter.course_leader}
                      <br />
                      <em>{workshop.frontmatter.title}</em>
                      <br />
                      <em>{workshop.frontmatter.subtitle}</em>
                    </WorkshopLink>
                  );
                })}
              </WorkshopsList>
            </>
          )}
        </Context.Consumer>
      </RightHalf>
    </SiteContainer>
  );
};

export default NotFoundPage;
